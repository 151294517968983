import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from "gatsby-image"

const TemplateImages = ({ src, className = "", style }) => {
  const data = useStaticQuery(graphql`
    query {
      ez1: file(relativePath: { eq: "ezimac/ez-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      logo: file(relativePath: { eq: "ezimac/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez2: file(relativePath: { eq: "ezimac/ez-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez3: file(relativePath: { eq: "ezimac/ez-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez4: file(relativePath: { eq: "ezimac/ez-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez5: file(relativePath: { eq: "ezimac/ez-5.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez6: file(relativePath: { eq: "ezimac/ez-6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez7: file(relativePath: { eq: "ezimac/ez-7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez8: file(relativePath: { eq: "ezimac/ez-8.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez9: file(relativePath: { eq: "ezimac/ez-9.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez10: file(relativePath: { eq: "ezimac/ez-10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez11: file(relativePath: { eq: "ezimac/ez-11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez12: file(relativePath: { eq: "ezimac/ez-12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez13: file(relativePath: { eq: "ezimac/ez-13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ez14a: file(relativePath: { eq: "ezimac/ez-14a.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez14b: file(relativePath: { eq: "ezimac/ez-14b.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ez14c: file(relativePath: { eq: "ezimac/ez-14c.png" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (!data[src]) {
    console.error("image not found:", src)
  }
  return (
    <GatsbyImage
      fluid={data[src].childImageSharp.fluid}
      className={`img--${src} ${className}`}
      style={style}
    />
  )
}
export default TemplateImages
