import React from "react"

import ProjectAnimations from "../../components/projects/projectAnimations"
import Slide from "../../components/projects/Slide"
import Spacer from "../../components/projects/Spacer"

import LogoSVG from "../../assets/svg/ezimac/logo.svg"

import DesktopVideo from "../../assets/images/ezimac/video.mp4"

import "./template-styles.scss"
import "./ezimac.scss"
import Img from "../../assets/images/ezimac-images"
import useScreenTranslate from "../../hooks/useScreenTranslate"

import SEO from "../../components/seo"
import RightArrowSVG from "../../assets/svg/right-arrow.svg"
import TransitionLink from "../../components/TransitionLink"
import NavigationOrbs from "../../components/NavigationOrbs"

const black = "#212931"
const white = "#ffffff"
const darkGrey = "#A7A7A7"
const lightGrey = "#E3E3E3"

const EzimacProject = () => {
  ProjectAnimations()
  const {
    scrollerSectionRef,
    scrollerScreenContainerRef,
    scrollerScreenRef,
    screenTranslate,
  } = useScreenTranslate({ durationInScreens: 2 })
  return (
    <div className="project ezimac">
      <SEO
        title="Ezimac"
        description="A Mac-styled brand platform for a provider that takes away the IT headaches and makes everything Mac better. Strategy, brand identity, tone of voice, key messaging and content, website, environmental, design collateral."
      />

      <NavigationOrbs />

      <Slide className="intro">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez1" className="h-full" />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: black }}
        >
          <div>
            <h1>Ezimac</h1>
            <p>
              When your Mac flips out it’s Ezimac Sales & Support that saves
              your bacon. Although the proposition for the brand platform
              concept was all in the name, a more inspiring one was at our
              fingertips. Command was the key. <br />
              <br />
              Strategy, brand identity, tone of voice, key messaging and copy,
              website, fit-out design, design collateral.
            </p>
          </div>
        </div>
      </Slide>

      <Slide className="section-1">
        <div
          className="panel--w-half panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: darkGrey }}
        >
          <LogoSVG />
        </div>
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: white, color: black }}
        >
          <div>
            <p>
              Command makes things happen. Command makes life easy. Command
              undoes the f*** ups. Command became the logo and everything
              Mac-flavoured followed.
            </p>
          </div>
        </div>
      </Slide>
      <Slide className="section-2">
        <Spacer />
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez2" className="h-full" />
        </div>
      </Slide>

      <div className="section-3 carousel-container section-wrapper">
        <div className="panel--w-full pin-once-scrolled">
          <div
            className="panel--w-half panel--h-screen centered-content "
            style={{ backgroundColor: lightGrey, color: black }}
          >
            <div className="panel--text">
              <p>
                Keyboard shortcuts became key messages. Target-market specific
                jokes that guarantee engagement.
              </p>
            </div>
          </div>
        </div>

        <div className="items-container-parent pin-for-400 pin-fully-scrolled flex flex-wrap">
          <Spacer />
          <div
            className="items-container panel--w-half flex flex-col "
            style={{ backgroundColor: darkGrey }}
          >
            <Img src="ez14a" className="item" />
            <Img src="ez14b" className="item" />
            <Img src="ez14c" className="item" />
          </div>
        </div>
      </div>

      <Slide className="section-4z">
        <div
          className="panel--w-full panel--h-screen panel--image panel--image--centered"
          style={{ backgroundColor: darkGrey }}
        >
          <video src={DesktopVideo} autoPlay playsInline muted loop></video>
        </div>
      </Slide>

      <Slide className="section-4">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ez4" className="h-full" />
        </div>
      </Slide>

      <Slide className="section-5">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content"
          style={{ backgroundColor: lightGrey }}
        >
          <div className="panel--text">
            <p style={{ color: black }}>
              An accommodating client provided hardware and enthusiastic
              participation for videos created for the bespoke website. They
              demonstrate that Ezimac can totally help with everything. Nearly.
              Retro-styled icons pixelate into shape, and a keyboard key menu
              lights up with interaction.
              <br />
              <br />
              Experience the full site{" "}
              <a
                href="https://ezimac.co.nz/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-semibold underline"
              >
                here
              </a>
            </p>
          </div>
        </div>
      </Slide>
      {/* <Slide className="section-6">
          <div className="panel--w-full panel--h-screen panel--image panel--image--full bg-red-500"></div>
        </Slide> */}
      <div ref={scrollerSectionRef}>
        <Slide className="section-6">
          <div className="ipad panel--w-full panel--h-screen screen-container">
            <div className="screen-container__inner-container">
              <Img src="ez5" className="screen-container__frame" />
              <div
                className="screen-container__screen "
                ref={scrollerScreenContainerRef}
              >
                <div
                  ref={scrollerScreenRef}
                  className="screen-container__translate"
                  style={{ transform: `translateY(${screenTranslate}%)` }}
                >
                  <Img src="ez6" className="screen-container__frame" />
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </div>

      <Slide className="section-7">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ez7" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-8">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ez8" className="h-full" />
        </div>
      </Slide>
      <div className="section-9 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: lightGrey, color: black }}
        >
          <div className="panel--text">
            <p>
              Old keyboards found a new life. Over 350 keys were plucked from
              the dead to recreate a work of art for the Ezimac office walls.
            </p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-10">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez10" className="h-full" />
        </div>{" "}
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez9" className="h-full" />
        </div>
      </Slide>
      {/* <div className="section-11 flex flex-wrap pin-for-400">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez9" className="h-full" />
        </div>
        <Spacer />
      </div> */}
      {/* <Spacer /> */}
      <Slide className="section-12">
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez11" className="h-full" />
        </div>
        <div className="panel--w-half panel--h-screen panel--image panel--image--full">
          <Img src="ez12" className="h-full" />
        </div>
      </Slide>
      <Slide className="section-13">
        <div className="panel--w-full panel--h-screen panel--image panel--image--full">
          <Img src="ez13" className="h-full" />
        </div>
      </Slide>
      <div className="section-14 flex flex-wrap pin-for-400">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen centered-content "
          style={{ backgroundColor: lightGrey, color: black }}
        >
          <div className="panel--text">
            <p>The Icon of an Icon artwork commands attention.</p>
          </div>
        </div>
      </div>
      <Spacer />
      <Slide className="section-15">
        <Spacer />
        <div
          className="panel--w-half panel--h-screen panel--text centered-content"
          style={{ backgroundColor: lightGrey, color: black }}
        >
          <div>
            <p>
              Ezimac’s brand platform gave them an engaging brand personality
              and provided a porthole to the vast community whose lifeblood
              depends on the tools of the trade doing what they bloody-well
              should.
              <br />
              <br />
              <TransitionLink
                to="/projects/red-and-black/"
                color="#de5034"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Next project
              </TransitionLink>
              <br />
              <TransitionLink
                to="/#projects"
                color="white"
                className="underline"
              >
                <RightArrowSVG
                  className="inline-block transform rotate-180"
                  style={{ width: "0.5em", marginRight: "0.5em" }}
                />
                Back to all projects
              </TransitionLink>
            </p>
          </div>
        </div>
      </Slide>
    </div>
  )
}

export default EzimacProject
